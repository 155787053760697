import React from 'react';
import scheduling from "../assets/scheduling.jpg"

const PlanningAndScheduling = () => {
  const style = {
    container : "pt-[7rem] pl-[3rem] pb-[10rem] space-x-[24rem] flex flex-row", 
    title: "font-bold pb-1", 
    list: "list-disc", 
    bullet: "py-[0.5rem]",
    imageContainer: "w-[500px] h-[500px] pl-[8rem]",
    image: "max-w-full max-h-full"
  }
  return (
    <div className={style.container} name = "Planning and Scheduling">
        <div>
        <h1 className={style.title}>Planning and Scheduling</h1>
                <ul className={style.list}>
                    <li className={style.bullet}>Development of standard WBS</li>
                    <li className={style.bullet}>Schedule Development (Level 1, Level 2, Level 3) with Project Teams</li>
                    <li className={style.bullet}>Schedule Scenario Analysis and Baseline</li>
                    <li className={style.bullet}>Schedule Update</li>
                    <li className={style.bullet}>Schedule Reporting (Earned Value and Variance Analysis)</li>
                </ul>
        </div>
        <div className= {style.imageContainer}>
            <img className= {style.image} src = {scheduling}></img>
        </div>
        
    </div>
  )
}

export default PlanningAndScheduling






