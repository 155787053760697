import React from 'react';
import homeImage from "../assets/backgroundImage.jpg";
import Navbar from './Navbar';

const Home = () => {
  return (
    <div>
        <Navbar />
            <div className="bg-cover bg-center flex justify-center items-center text-white 
            font-bold h-[860px] w-full" style={{ backgroundImage: `url(${homeImage})`}}>
                <div className="text-center text-[8rem]">
                    <p>RISK PMO</p>
                </div>
            </div>
    </div>
  )
}

export default Home