import React from 'react';
import pmoServices from '../assets/pmoServices.jpg'

const PMOServices = () => {
    const style = {
    container : "pt-[8rem] pl-[3rem] pb-[8rem] flex flex-row", 
    title: "font-bold pb-1", 
    list: "list-disc", 
    bullet: "py-[0.25rem]",
    imageContainer: "w-[600px] h-[650px] pt-[5rem] pl-[7rem]",
    image: "max-w-full max-h-full right-[-105px] relative"
    }
  return (
    <div className={style.container} name = "PMO Services">
        <div>
            <h1 className={style.title}>PMO Services</h1>
                <h3>Project Oversight</h3>
                    <ul className={style.list}>
                        <li className={style.bullet}>Development of project management and project controls oversight framework</li>
                        <li className={style.bullet}>Plan and perform project oversight reviews (project management and project controls)</li>
                        <li className={style.bullet}>Guidance to Project Team based on project oversight review findings</li>
                        <li className={style.bullet}>Project Management and Project Controls Training</li>
                        <li className={style.bullet}>Development of contractor audit / oversight framework</li>
                        <li className={style.bullet}>Plan and perform contractor oversight audits / reviews</li>
                        <li className={style.bullet}>Contractor Claims review and validation</li> 
                    </ul>
                <h3>Process Development and Improvement</h3>
                    <ul className={style.list}>
                        <li className={style.bullet}>Development of project stage gate processes for various types of projects</li>
                        <li className={style.bullet}>Development of project governance / assurance framework</li>
                        <li className={style.bullet}>Development of Project Reporting & KPI Framework</li>
                        <li className={style.bullet}>Optimization and standardization of excessive Project Reports</li>
                        <li className={style.bullet}>Development of project controls framework (procedures & templates)</li>
                        <li className={style.bullet}>Development of project categorization model</li>
                        <li className={style.bullet}>Development and rollout of Change Management Framework (Cost, Schedule, Scope)</li>
                        <li className={style.bullet}>Roles and responsibility development for Project Controls Organization</li>
                        <li className={style.bullet}>Development of Project Controls Requirements for Subcontractors and Bidding Process</li>
                        <li className={style.bullet}>Development and rollout of Earned Value Management Framework</li>
                    </ul>
                </div>
            <div className= {style.imageContainer}>
                <img className= {style.image} src = {pmoServices}></img>
            </div>
    </div>
  )
}

export default PMOServices