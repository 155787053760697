import Contact from './components /Contact';
import Home from './components /Home';
import IndustryExposure from './components /IndustryExposure';
import OtherServices from './components /OtherServices';
import PlanningAndScheduling from './components /PlanningandScheduling';
import PMOServices from './components /PMOServices';
import ProjectRiskManagement from './components /ProjectRiskManagement';

function App() {
  return (
    <div className="App">
      <Home />
      <ProjectRiskManagement />
      <PMOServices/>
      <PlanningAndScheduling/>
      <OtherServices/>
      <IndustryExposure/>
      <Contact/>
    </div>
  );
}

export default App;
