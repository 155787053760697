import React from 'react';
import {Link} from "react-scroll";

const Navbar = () => {
  return (
<div className="w-full">
  <nav className="flex flex-wrap fixed top-0 left-0 right-0 z-10 items-center justify-between px-1 w-full text-white bg-black">
    <div>
      <h1 className="text-2xl font-bold text-white cursor-pointer my-0">
        <Link to={"Home"}>RISK PMO</Link>
      </h1>
    </div>
    <div>
      <ul className="flex flex-col mt-4 md:flex-row md:space-x-4 md:mt-0 md:text-md md:font-medium cursor-pointer">
        <li className="hover:bg-white hover:rounded-lg p-2 hover:text-black duration-100">
          <Link to="Project Risk Management Services" smooth duration={200}>
            Project Risk Management Services
          </Link>
        </li>
        <li className="hover:bg-white hover:rounded-lg p-2 hover:text-black duration-100">
          <Link to="PMO Services" smooth duration={200}>
            PMO Services
          </Link>
        </li>
        <li className="hover:bg-white hover:rounded-lg p-2 hover:text-black duration-100">
          <Link to="Planning and Scheduling" smooth duration={200}>
            Planning and Scheduling
          </Link>
        </li>
        <li className="hover:bg-white hover:rounded-lg p-2 hover:text-black duration-100">
          <Link to="Other Services" smooth duration={200}>
            Other Services
          </Link>
        </li>
        <li className="hover:bg-white hover:rounded-lg p-2 hover:text-black duration-100">
          <Link to="Industry Exposure" smooth duration={200}>
            Industry Exposure
          </Link>
        </li>
        <li className="hover:bg-white hover:rounded-lg p-2 hover:text-black duration-100">
          <Link to="Contact" smooth duration={200}>
            Contact
          </Link>
        </li>
      </ul>
    </div>
  </nav>
</div>
  )
}

export default Navbar