import React from 'react';
import otherServices from '../assets/otherServices.jpg'

const OtherServices = () => {
  const style = {
    container : "pt-[7rem] pl-[3rem] pb-[10rem] flex flex-row space-x-[19rem]", 
    title: "font-bold pb-1", 
    list: "list-disc", 
    bullet: "py-[0.25rem]",
    imageContainer: "w-[640px] h-[400px]",
    image: "mt-[1.5rem] max-w-full max-h-full"
  }
  return (
    <div className={style.container} name = "Other Services">
        <div>
            <h1 className={style.title}>Other Services</h1>
                    <ul className={style.list}>
                        <li className={style.bullet}>Development of standard WBS, CBS and Control Accounts</li>
                        <li className={style.bullet}>Project Controls Deliverable Matrix as per project categories</li>
                        <li className={style.bullet}>Project Controls Calendar Development</li>
                        <li className={style.bullet}>Project Controls Infrastructure Setup</li>
                        <li className={style.bullet}>Any other specific services for project controls discipline</li>
                        <ul className={style.list}>
                            <li className={style.bullet}>Cost Estimation</li>
                            <li className={style.bullet}>Cost Control</li>
                            <li className={style.bullet}>Planning</li>
                            <li className={style.bullet}>Scheduling</li>
                            <li className={style.bullet}>Project Change Management</li>
                            <li className={style.bullet}>Risk Management</li>
                            <li className={style.bullet}>Progress & Performance Measurements</li>
                            <li className={style.bullet}>Earned Value Management</li>
                            <li className={style.bullet}>Project Reporting</li>
                        </ul>
                    </ul>
        </div>
        <div className= {style.imageContainer}>
            <img className= {style.image} src = {otherServices}></img>
        </div>
    </div>
    
  )
}

export default OtherServices






