/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import oilMining from '../assets/oil_mining.jpg';

const IndustryExposure = () => {
    const style = {
    container : "pt-[7rem] pl-[3rem] pb-[6rem] flex flex-row", 
    title: "font-bold pb-1", 
    description: "pl-0.5",
    list: "list-disc", 
    bullet: "py-[0.5rem]",
    imageContainer: "w-[640px] h-[500px] pl-[8rem]",
    image: "max-w-full max-h-full"
  }
  return (
    <div className={style.container} name = "Industry Exposure">
        <div>
            <h1 className={style.title}>Industry Exposure</h1>
            <h6 className={style.description}>Experienced in providing project control 
              services for major, medium, smaller capital and maintenance projects 
                across Canada. 
            </h6>
            <ul className={style.list}>
                <li className={style.bullet}>Oil and Gas Projects</li>
                <li className={style.bullet}>Nuclear Power Projects</li>
                <li className={style.bullet}>Mining Projects</li>
                <li className={style.bullet}>Infastructure Projects</li>
                <li className={style.bullet}>Process Improvement Projects</li>
            </ul>
        </div>
        <div className= {style.imageContainer}>
            <img className= {style.image} src = {oilMining}></img>
        </div>
    </div>
  )
}

export default IndustryExposure