import React from 'react'

const Contact = () => {
  return (
    <div name = "Contact">
            <form action = "https://getform.io/f/90fc18a4-69dd-49f5-a89d-b66e828d2026" method = "POST" className = "flex flex-col px-[3rem]">
                <h2 className = "text-4xl font-bold inline pt-16 pb-2 text-black">Contact</h2>
                <h6>2026597 Alberta Inc, Nishikant_jawne@yahoo.com</h6>
                <input className = "p-2 bg-black border-2 rounded-md border-white mr-7" type = "text" name = "name" placeholder = "Your Name"></input>
                <input className = "p-2 bg-black my-4 border-2 rounded-md border-white mr-7" type = "text" name = "email" placeholder = "Your Email"></input>
                <textarea className = "p-2 bg-black my-4 border-2 rounded-md border-white mr-7 mt-2" rows = "10" name = "message"   placeholder = "Your Message"></textarea>
                <button className ="bg-black text-white hover:bg-white hover:text-black rounded-full p-3 w-1/6 mb-12">Get in Touch</button>
            </form>
        <div>

        </div>
    </div>
  )
}

export default Contact