import React from 'react';
import projectRiskManagementServices from '../assets/projectRiskManagementServices.jpg';

const ProjectRiskManagement = () => {
  const style = {
    container : "pt-[8rem] pl-[3rem] pb-[6rem] flex flex-row", 
    title: "font-bold pb-1", 
    list: "list-disc", 
    imageContainer: "w-[800px] h-[700px] pt-[7.5rem] pl-[7rem]",
    image: "max-w-full max-h-full right-[-120px] relative"
    }
  return (
    <div className={style.container} name = "Project Risk Management Services">
        <div>
        <h1 className={style.title}>Project Risk Management Services</h1>
            <h3>Quantitative Risk Assessment / Analysis (Monte Carlo Simulation)</h3>
                <ul className={style.list}>
                    <li>Contingency Calculations using Quantitative Risk Modelling</li>
                    <li>Cost Forecast Scenario with simulated models</li>
                    <li>Quantitative Cost Risk Assessment (QCRA)</li>
                    <li>Quantitative Schedule Risk Assessment (QSRA)</li>
                    <li>Sensitivity Analysis </li>
                    <li>Risk Based Cashflow Analysis</li>
                    <li>Specific Quantitative Analysis such as COVID-19 Impact on Cost and Schedule</li>
                </ul>
             <h3>Project Risk Planning</h3>
                <ul className={style.list}>
                    <li>Development of customized Risk Management Plan for projects / programs</li>
                    <li>Establishment of customized Risk Breakdown Structure (RBS)</li>
                    <li>Set up of customized Risk Register for the project / program / business unit</li>
                    <li>Probability and Impact Determination (P-I Matrix)</li>
                    <li>Risk Heat Map Development (Qualitative)</li>
                </ul>
            <h3>Risk Response Strategy Development and Monitoring</h3>
                <ul className={style.list}>
                    <li>Risk Response Planning Strategies</li>
                    <li>Response Plan Monitoring and Tracking</li>
                    <li>Residual and Secondary Risks</li>
                </ul>
            <h3>Project Risk Governance</h3>
                <ul className={style.list}>
                    <li>Risk Management Procedures Development</li>
                    <li>Complete Process Flow</li>
                    <li>Risk Deliverable Templates Development</li>
                    <li>Risk Reviews / Audit Planning</li>
                    <li>Risk Management Tool Recommendations and Customization</li>
                </ul>
            <h3>Project Risk Reporting</h3>
                <ul className={style.list}>
                    <li>Risk Dashboard for Management</li>
                    <li>Risk Summary with KPI</li>
                    <li>Specific Risk Reporting</li>
                </ul>
            <h3>Project Risk Management Training </h3>
                <ul className={style.list}>
                    <li>Risk Identification Techniques</li>
                    <li>Qualitative and Quantitative Assesment</li>
                    <li>Risk Reviews, Monitoring Tracking</li>
                    <li>Customized Risk Reporting</li>
                </ul>
            </div>
            <div className= {style.imageContainer}>
                <img className= {style.image} src = {projectRiskManagementServices}></img>
            </div>
    </div>
  )
}

export default ProjectRiskManagement